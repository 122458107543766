export const products = [
    {
        id: 1,
        title: "product 1",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 2,
        title: "product 2",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 3,
        title: "product 3",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 4,
        title: "product 4",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
];
