import PropTypes from "prop-types";
import Image from "next/image";
import Anchor from "@ui/anchor";
import { ImageType } from "@utils/types";
import AppContext from "@context/app-context";
import { useContext } from "react";

const loaderProp = ({ src, width }) => {
    return src + "?w=" + width;
};

const SingleSlide = ({
    image,
    title,
    path,
    description,
    authors,
    auction_date,
    award_image,
}) => {
    const { isPCSmall } = useContext(AppContext);

    return (
        <>
            {image?.src && (
                <Image
                    className="slider-bg"
                    src={image.src}
                    alt="Slider BG"
                    quality={100}
                    fill
                    sizes="100vw"
                    style={{
                        objectFit: "cover",
                    }}
                    loader={loaderProp}
                    priority={true}
                />
            )}

            <div
                className="rn-banner-wrapper g-5 "
                style={{ marginLeft: 0, flexWrap: "wrap" }}
            >
                <div className="col-xl-4 col-lg-12 col-12 order-3 order-xl-1 order-sm-1">
                    <Anchor path={path}>
                        <h2
                            className="title pl--30"
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    </Anchor>
                </div>
            </div>
        </>
    );
};

SingleSlide.propTypes = {
    title: PropTypes.string,
    path: PropTypes.string,
    description: PropTypes.string,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            slug: PropTypes.string,
            image: ImageType,
        })
    ),
    bitCount: PropTypes.number,
    auction_date: PropTypes.string,
    image: ImageType,
    award_image: ImageType,
};

export default SingleSlide;
