export const news = [
    {
        id: 1,
        title: "Post 1",
        author: "Author 1",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 2,
        title: "Post 2",
        author: "Author 1",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 3,
        title: "Post 3",
        author: "Author 1",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 4,
        title: "Post 4",
        author: "Author 1",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 5,
        title: "Post 5",
        author: "Author 2",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 6,
        title: "Post 6",
        author: "Author 2",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 7,
        title: "Post 7",
        author: "Author 1",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
    {
        id: 8,
        title: "Post 8",
        author: "Author 2",
        description:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat accusantium ipsam eos ducimus dolores provident, non temporibus esse aut maxime totam, quae eligendi vel qui possimus incidunt dolorum veniam reiciendis.",
    },
];
