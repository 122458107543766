import Anchor from "@ui/anchor";
import PropTypes from "prop-types";
import { ImageType } from "@utils/types";
import AppContext from "@context/app-context";
import { useContext } from "react";

const Service = ({ title, subtitle, path, description, image }) => {
    const { isSmallMobile } = useContext(AppContext);

    return (
        <div
            className="rn-service-one color-shape-7"
            style={{ padding: isSmallMobile && "70px 15px 20px" }}
        >
            <div className="inner">
                <div className="icon">
                    {image?.src && (
                        // eslint-disable-next-line @next/next/no-img-element
                        <img src={image.src} alt={image?.alt || title} />
                    )}
                </div>
                <div className="subtitle">{subtitle}</div>
                <div className="content">
                    <h4 className="title">
                        <Anchor path={path}>{title}</Anchor>
                    </h4>
                    <p
                        className="description"
                        style={{ marginBottom: isSmallMobile && 20 }}
                    >
                        {description}
                    </p>
                    <Anchor className="read-more-button" path={path}>
                        <i className="feather-arrow-right" />
                    </Anchor>
                </div>
            </div>
        </div>
    );
};

Service.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: ImageType,
};

export default Service;
