import { Component } from "react";
import dynamic from "next/dynamic";
import clsx from "clsx";
import { Spinner } from "@fluentui/react-components";

// Data
import homepageData from "@data/homepages/home-page.json";
// Utils
import { normalizedData } from "@utils/methods";

// CSS Modules
import styles from "./home.module.css";

// demo data
import { products } from "@page-components/home/components/demo-data/products";
import { news } from "@page-components/home/components/demo-data/news"; 
import ServiceArea from "@commons/services"; 
import HeroArea from "@commons/hero/";

const ProductContainer = dynamic(
    () => import("@page-components/home/containers/product"),
    {
        ssr: false,
    }
);
const NewsContainer = dynamic(
    () => import("@page-components/home/containers/news"),
    {
        loading: () => <Spinner label="Đang tải..." />,
        ssr: false,
    }
);

const Topbar = dynamic(() => import("@layout/top-bar-horizontal"), {
    loading: () => <Spinner label="Đang tải..." />,
    ssr: false,
});

class HomeContainer extends Component {
    constructor(props: any) {
        super(props);

        const content = normalizedData(homepageData?.content);

        this.setHeightTop = this.setHeightTop.bind(this);

        this.state = {
            heightTopbar: 0,
            content: content,
            products: products,
            news: news,
        };
    }

    setHeightTop(height) {
        this.setState({
            ...this.state,
            heightTopbar: height,
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.products.map((p) => p.id).join(",") !==
            nextState.products.map((p) => p.id).join(",") ||
            this.state.news.map((n) => n.id).join(",") !==
            nextState.news.map((n) => n.id).join(",") ||
            this.state.heightTopbar !== nextState.heightTopbar
        );
    }

    render() {
        const { products, news, heightTopbar } = this.state;

        return (
            <>
                <div className={clsx(styles.home_container)}>
                    <Topbar setHeightTop={this.setHeightTop} />

                    <div
                        className={clsx(styles.home_wrapper_content)}
                        style={{
                            marginTop: `${heightTopbar}px`,
                        }}
                    >   <HeroArea data={this.state.content["hero-section"]} />
                        <ServiceArea
                            id="list-item-4"
                            space={2}
                            data={this.state.content["service-section"]}
                        />
                        <ProductContainer
                            products={products}
                            title="Sản phẩm"
                        />
                        <NewsContainer title="Bài viết" news={news} />
                    </div>
                </div>
            </>
        );
    }
}

export default HomeContainer;
